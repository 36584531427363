var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg flex f-d-c f-ai-c" }, [
        _c("div", { staticClass: "bg-ic flex f-ai-c" }, [
          _c("i"),
          _vm._v(
            " " +
              _vm._s(
                _vm.prescriptionInfo.status === 1
                  ? "待支付"
                  : _vm.prescriptionInfo.status === 2
                  ? "待收货"
                  : _vm.prescriptionInfo.status === 3
                  ? "已完成"
                  : _vm.prescriptionInfo.status === 4
                  ? "已取消"
                  : _vm.prescriptionInfo.status === 5
                  ? "待发货"
                  : _vm.prescriptionInfo.status === 6
                  ? "已退款"
                  : "未知状态"
              ) +
              " "
          ),
        ]),
        [2, 3].indexOf(_vm.prescriptionInfo.status) > -1
          ? _c(
              "div",
              {
                staticClass: "express flex f-ai-c",
                on: { click: _vm.toExpress },
              },
              [_c("span", [_vm._v("查看物流状态")]), _c("i")]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "wrap" },
        [
          _c("div", { staticClass: "item one" }, [
            _c("div", [
              _c("div", { staticClass: "top flex f-ai-c" }, [
                _c("i"),
                _vm._v(
                  " " +
                    _vm._s(_vm.addressInfo.name) +
                    " " +
                    _vm._s(_vm.addressInfo.mobilePhone) +
                    " "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.addressInfo.address))]),
            ]),
          ]),
          _c("div", { staticClass: "item two" }, [
            _c("div", { staticClass: "top" }, [
              _c("span", [_vm._v("用药人信息")]),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.patientInfo.name) +
                    " " +
                    _vm._s(_vm.patientInfo.gender === 1 ? "男" : "女") +
                    " " +
                    _vm._s(_vm.patientInfo.age) +
                    "岁"
                ),
              ]),
            ]),
            _c("div", { staticClass: "center" }, [
              _vm._v(_vm._s(_vm.prescriptionInfo._rp)),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _vm._v(
                "共" +
                  _vm._s(_vm.prescriptionInfo.jiliang) +
                  "剂，每日" +
                  _vm._s(_vm.prescriptionInfo.jlPerDay) +
                  "剂，1剂分" +
                  _vm._s(_vm.prescriptionInfo.times) +
                  "次服用"
              ),
            ]),
          ]),
          _c("div", { staticClass: "order radius mt-8" }, [
            _vm._v(" 预约单号：" + _vm._s(_vm.prescriptionInfo.orderId) + " "),
          ]),
          _c("div", { staticClass: "fy radius mt-8" }, [
            _vm.prescriptionInfo.outpatientCare
              ? _c("div", { staticClass: "flex f-jc-sb" }, [
                  _c("span", [_vm._v("诊费")]),
                  _c("i", [
                    _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.outpatientCare)),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "flex f-jc-sb" }, [
              _c("span", [_vm._v("药费")]),
              _c("i", [
                _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.medicinePrice)),
              ]),
            ]),
            _c("div", { staticClass: "flex f-jc-sb" }, [
              _c("span", [_vm._v("加工费")]),
              _c("i", [
                _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.processingFee)),
              ]),
            ]),
            _vm.prescriptionInfo.logisticsFee
              ? _c("div", { staticClass: "flex f-jc-sb" }, [
                  _c("span", [_vm._v("运费")]),
                  _c("i", [
                    _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.logisticsFee)),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "border-line" }),
            _c("div", { staticClass: "flex-end" }, [
              _c("div", { staticClass: "total-fee flex f-jc-sb f-ai-c" }, [
                _vm._v(" 总计： "),
                _c("span", [
                  _vm._v(" ￥"),
                  _c("i", [
                    _vm._v(
                      _vm._s(
                        _vm.prescriptionInfo.totalFee.toString().split(".")[0]
                      )
                    ),
                  ]),
                  _vm.prescriptionInfo.totalFee.toString().split(".")[1]
                    ? _c("i", [
                        _vm._v(
                          "." +
                            _vm._s(
                              _vm.prescriptionInfo.totalFee
                                .toString()
                                .split(".")[1]
                            )
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm.prescriptionInfo.status === 1
            ? _c("div", { staticClass: "pay flex f-jc-sb f-ai-c" }, [
                _c(
                  "div",
                  { staticClass: "cancel", on: { click: _vm.clickCancel } },
                  [_c("span", [_vm._v("取消")])]
                ),
                _c(
                  "div",
                  {
                    staticClass: "determine",
                    on: {
                      click: function ($event) {
                        return _vm.clickShowModal(1)
                      },
                    },
                  },
                  [_c("span", [_vm._v("去支付")])]
                ),
              ])
            : _vm._e(),
          _c(
            "van-popup",
            {
              attrs: { round: "" },
              model: {
                value: _vm.showModal,
                callback: function ($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal",
              },
            },
            [
              _c("div", { staticClass: "pop-content" }, [
                _c("div", { staticClass: "pop-title" }, [
                  _c("span", [_vm._v("费用明细")]),
                ]),
                _vm.prescriptionInfo.outpatientCare
                  ? _c("div", [
                      _c("span", [_vm._v("诊费")]),
                      _c("label", [
                        _vm._v(
                          "￥" + _vm._s(_vm.prescriptionInfo.outpatientCare)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("span", [_vm._v("药费")]),
                  _c("label", [
                    _vm._v("￥" + _vm._s(_vm.prescriptionInfo.medicinePrice)),
                  ]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("加工费")]),
                  _c("label", [
                    _vm._v("￥" + _vm._s(_vm.prescriptionInfo.processingFee)),
                  ]),
                ]),
                _vm.prescriptionInfo.logisticsFee
                  ? _c("div", { staticClass: "flex f-jc-sb" }, [
                      _c("span", [_vm._v("运费")]),
                      _c("i", [
                        _vm._v(
                          "￥ " + _vm._s(_vm.prescriptionInfo.logisticsFee)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "title2" }, [
                  _c("span", [_vm._v("总计")]),
                  _c("label", { staticClass: "red" }, [
                    _vm._v("￥" + _vm._s(_vm.prescriptionInfo.totalFee)),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "为您配药后，药品不可他用，订单不可取消，请您支付前仔细核对订单信息。"
                  ),
                ]),
                _c("div", { staticClass: "bottom" }, [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function ($event) {
                          return _vm.clickShowModal(2)
                        },
                      },
                    },
                    [_c("span", [_vm._v("关闭")])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "topay",
                      on: {
                        click: function ($event) {
                          return _vm.clickShowModal(3)
                        },
                      },
                    },
                    [_c("span", [_vm._v("确认支付")])]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "flex f-ai-c back-dialog",
          model: {
            value: _vm.showCancelOrder,
            callback: function ($$v) {
              _vm.showCancelOrder = $$v
            },
            expression: "showCancelOrder",
          },
        },
        [
          _c("h1", [_vm._v("提示")]),
          _c("span", [_vm._v("是否确认取消该订单？")]),
          _c("div", { staticClass: "back-btns" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeCancelDialog(1)
                  },
                },
              },
              [_vm._v("确定")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeCancelDialog()
                  },
                },
              },
              [_vm._v("我再想想")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }